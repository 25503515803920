import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  deleteCompanyBrand,
  deleteCompanyLocation,
  getCompanyBrands,
  getCompanyLocation,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const CompanyDetails = () => {
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation();
  const { header } = useParams();
  const isSlider = useSlider();

  const { data: brandData, refetch } = useQuery({
    queryKey: ["company-details", header],
    queryFn: async () => {
      const resp = await getCompanyBrands(header);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) =>
      id?.locationId
        ? deleteCompanyLocation(id?.locationId, header)
        : deleteCompanyBrand(id, header),
    onSuccess: (resp) => {
      refetch();
      refetchLocation();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  const { data: locations, refetch: refetchLocation } = useQuery({
    queryKey: ["company-locations", header],
    queryFn: async () => {
      let resp = await getCompanyLocation(header);
      return resp?.data?.data;
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <NavigateBack>{t("companyManagement.companyDetails")}</NavigateBack>
        </div>

        <div className="basic_Info company-info">
          <div>
            <p className="font-18 colorDark mb-3">
              {t("companyManagement.companyName")}:{" "}
              <span className="colorLight ms-2">{brandData?.name}</span>
            </p>

            <p className="fontSize16 mb-3">
              {brandData?.corporateAddress?.fullAddress} <br />{" "}
              {brandData?.corporateAddress?.street}
              <br />
              {brandData?.corporateAddress?.city}{" "}
              {brandData?.corporateAddress?.state}
              <br /> {brandData?.corporateAddress?.zipCode}{" "}
              {brandData?.corporateAddress?.country}
            </p>
          </div>

          {/*******************************All Location Table**********************/}
          <div className="mb-4 companyLocInfo">
            <div className="d-flex align-items-center justify-content-between">
              <p className="font-20 colorDark">
                {t("addCompany.allLocations")}
              </p>
            </div>
            <div className="table-responsive companyManagTable tableOut mt-4">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th> {t("addCompany.name")}</th>
                    <th>{t("addCompany.location")}</th>
                    <th>{t("globals.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {locations?.locations?.locations &&
                  locations?.locations?.locations?.length > 0 ? (
                    locations?.locations?.locations?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data?.name}</td>
                          <td>{data?.billingAddress?.fullAddress}</td>
                          <td>
                            <Link to={`../location/${data?._id}/${header}`}>
                              <img
                                src="/images/eye.svg"
                                className="img-fluid actionIcon"
                              />
                            </Link>

                            <Link
                              to={`../companylocation/${header}?id=${locations?.company?._id}`}
                            >
                              <img
                                src="/images/Edit.svg"
                                className="img-fluid actionIcon"
                              />
                            </Link>

                            <img
                              src="/images/Delete.svg "
                              className="img-fluid actionIcon"
                              onClick={() =>
                                setShowDelete({ locationId: data?._id })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10} className="text-center">
                        <h4>{t("brand.locationNotFound")}</h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/*******************************All Brands Table**********************/}
          {brandData?.associatedBrands &&
            brandData?.associatedBrands?.length > 0 &&
            brandData?.associatedBrands?.map((item, index) => {
              return (
                <div className="mb-4 companyLocInfo" key={index}>
                  <div className="d-flex align-items-center">
                    <p className="font-20 colorDark">
                      {t("addCompany.brandName")}:
                      <span className="colorLight ms-2">
                        {item?.brand?.name}
                      </span>
                    </p>
                    <div className="ms-auto">
                      <Link
                        className="deleteBrand"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowDelete(item?._id);
                        }}
                      >
                        <img src="/images/Delete.svg" alt="delete" />
                      </Link>
                    </div>
                  </div>
                  <div className="table-responsive companyManagTable tableOut mt-4">
                    <table className="table tableDashboard">
                      <thead className="thead-dark">
                        <tr>
                          <th> {t("addCompany.name")}</th>
                          <th>{t("addCompany.location")}</th>
                          <th>{t("globals.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item?.locations && item?.locations?.length > 0 ? (
                          item?.locations?.map((data, index) => {
                            return (
                              <tr key={index}>
                                <td>{data?.name}</td>
                                <td>{data?.billingAddress?.fullAddress}</td>
                                <td>
                                  <Link
                                    to={`../location/${data?._id}/${header}`}
                                  >
                                    <img
                                      src="/images/eye.svg"
                                      className="img-fluid actionIcon"
                                    />
                                  </Link>

                                  <Link
                                    to={`../companylocation/${header}?id=${brandData?._id}`}
                                  >
                                    <img
                                      src="/images/Edit.svg"
                                      className="img-fluid actionIcon"
                                    />
                                  </Link>

                                  <img
                                    src="/images/Delete.svg "
                                    className="img-fluid actionIcon"
                                    onClick={() =>
                                      setShowDelete({ locationId: data?._id })
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className="text-center">
                              <h4>{t("brand.locationNotFound")}</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default CompanyDetails;
