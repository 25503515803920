import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import useSlider from "../../hooks/useSlider";
import { getDataList } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";

const TaskList = () => {
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const isSlider = useSlider();
  const { t } = useTranslation();
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);

  const { data } = useQuery({
    queryKey: ["data-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain &&
        (await getDataList(
          page,
          limit,
          search,
          constant.DATA_LIST.TASK_LIST,
          company?.domain,
          true,
          true
        ));
      return resp?.data?.data ?? [];
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("task.taskList")}</h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <Link className="userBtn btn-theme" to="../addnewtask">
              {t("globals.addNew")}
            </Link>
          </div>
        </div>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("task.taskListName")}</th>
                <th>{t("task.taskName")}</th>
                <th>{t("task.employeeName")}</th>
                <th>{t("task.createdOn")}</th>
                <th>{t("task.startTime")}</th>
                <th>{t("task.endTime")}</th>
                <th>{t("task.taskLocation")}</th>
                {/* <th>{t("task.comment")}</th> */}
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.datalists && data?.datalists?.length > 0 ? (
                data?.datalists?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.name}</td>
                      <td>{item?.details?.taskName}</td>
                      <td>{item?.customerDetails?.userInfo?.name ?? "--"}</td>
                      <td>{moment(item?.createdAt).format("lll")}</td>
                      <td>
                        {item?.details?.startTime
                          ? moment(item?.details?.startTime).format("ll")
                          : "--"}
                      </td>
                      <td>
                        {item?.details?.endTime
                          ? moment(item?.details?.endTime).format("ll")
                          : "--"}
                      </td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i?.name)?.join(", ")}
                      </td>
                      <td>
                        <Link
                          to={`../task-details/${item?._id}`}
                          role="button"
                          state={company}
                        >
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("task.noTaskListFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.datalists?.length}
        />
      </div>
    </div>
  );
};

export default TaskList;
