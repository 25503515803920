import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSlider from "../../hooks/useSlider";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
const Dashboard = () => {
  const isSlider = useSlider();
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} >
            <h2 className="mainhead font-24">Dashboard</h2>
          </Col>
          <Col xl={6}>
            <div className="d-flex justify-content-end align-items-center">
              <label className="labelText">Filter by:</label>
              <select
                className="form-select selectfeild"
                aria-label="Default select example"
              >
                <option selected>Weekly</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </Col>
        </Row>

        <div className="dash-widget ">
          <Row >
            <Col xl={9}>
              <Row>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total users</p>
                      <h4>{2562}</h4>
                    </div>
                    <div className="icon icon-two">
                      <img
                        src="/images/curve.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Restaurent</p>
                      <h4>{2562}</h4>
                    </div>
                    <div className="icon icon-one">
                      <img
                        src="/images/curve.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Products</p>
                      <h4>{2562}</h4>
                    </div>
                    <div className="icon icon-three">
                      <img
                        src="/images/curve.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6}>
                  <div className="widgetcard">
                    <div className="value">
                      <p>Total Transactions</p>
                      <h4>{2562}</h4>
                    </div>
                    <div className="icon icon-four">
                      <img
                        src="/images/curve.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={3}>
              <div className="revenewImg">
                <img src="/images/revenew.png" className="img-fluid"></img>
              </div>
            </Col>
          </Row>
          <div className="tableOuter">
            <Row>
              <Col xl={6}>
                <p className="font-20">Recently Added Users</p>
              </Col>
              <Col xl={6}>
                <p className="mb-0 text-end ">
                  <Link className="text-decoration-none linktext">
                    View All
                  </Link>
                </p>
              </Col>
            </Row>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Customer ID</th>
                    <th >User Name</th>
                    <th >Name</th>
                    <th >Email </th>
                    <th >Roles</th>
                    <th >Added </th>
                    <th >Action </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>@wadewarren</td>
                    <td>Wade Warren</td>
                    <td>wadewarren@gmail.com</td>
                    <td>User</td>
                    <td>05/29/2024</td>
                    <td>
                      <img
                        src="/images/eye.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Edit.svg"
                        className="img-fluid actionIcon"
                      ></img>
                      <img
                        src="/images/Delete.svg "
                        className="img-fluid actionIcon"
                      ></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
};

export default Dashboard;
