import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import "./../../admin/Usermanagement/addNewUser.css";

const Reportsindex = () => {
  const isSlider = useSlider();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6} >
            <h2 className="mainhead font-24">Reports & Analytics</h2>
          </Col>
          <Col xl={6}>
            <div className="d-flex align-items-center justify-content-end">
              <label className="labelText">Filter by:</label>
              <select
                className="form-select selectfeild"
                aria-label="Default select example"
              >
                <option selected>Weekly</option>
                <option value="1">Today</option>
                <option value="2">Yesterday</option>
                <option value="3">Weekly</option>
                <option value="3">Monthly</option>
                <option value="3">Every 3 Month</option>
                <option value="3">Every 6 Month</option>
                <option value="3">Every 9 month</option>
                <option value="3">Yearly</option>
              </select>
              <button className="userBtn btn-theme">Export Reports</button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={9}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">Transactions</label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Order ID</th>
                    <th >User Name</th>
                    <th >Amount</th>
                    <th >Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>#34567821</td>
                    <td>Wade Warren</td>
                    <td>$500</td>
                    <td>
                      <p className="greenBox">Completed</p>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>Wade Warren</td>
                    <td>$500</td>
                    <td>
                      <p className="greenBox yellowBox">Cancelled</p>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>Wade Warren</td>
                    <td>$500</td>
                    <td>
                      <p className="greenBox yellowBox">Pending</p>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>Wade Warren</td>
                    <td>$500</td>
                    <td>
                      <p className="greenBox">Completed</p>
                    </td>
                  </tr>
                  <tr>
                    <td>#34567821</td>
                    <td>Wade Warren</td>
                    <td>$500</td>
                    <td>
                      <p className="greenBox greenBox">Cancelled</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col xl={3}>
            <div className="revenewImg mt-4">
              <img src="./../images/revenew.png" className="img-fluid"></img>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">
                Product Waste Tracking
              </label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Item Name</th>
                    <th >Location</th>
                    <th >Prepped Quantity</th>
                    <th >Waste Quantity</th>
                    <th >Unit of Measure</th>
                    <th >Number of Label Printed</th>
                    <th >Net Weight</th>
                    <th >COGs Prepped</th>
                    <th >COGs Wasted</th>
                    <th >COGs Sold</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">
                      13th Street 47 W 13th Street 47 W 13th Street 47 W 13th
                      Street 47 W 13th Street 47 W 13th Street 47 W 13th Street
                      47 W 13th Street 47 W
                    </td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td>13th Street 47 W...</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>100</td>
                    <td>10kg</td>
                    <td>50</td>
                    <td>50</td>
                    <td>50</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="tableOuter" xl={12}>
            <div className="d-flex align-items-baseline justify-content-between">
              <label className="font-18 colorDark pb-3">Item Prepared</label>
              <p className="mb-0 text-end ">
                <Link className="text-decoration-none linktext">View All</Link>
              </p>
            </div>
            <div className="table-responsive tableOut">
              <table className="table tableDashboard">
                <thead className="thead-dark">
                  <tr>
                    <th >Item Name</th>
                    <th >User or Employee</th>
                    <th >Location</th>
                    <th >Quantity of Labels Printed</th>
                    <th >Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                  <tr>
                    <td>Blackened</td>
                    <td className="text">Wade Warren</td>
                    <td>13th Street 47 W 13th St, New York...</td>
                    <td>100</td>
                    <td>05/29/2024, 12:00 PM</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reportsindex;
