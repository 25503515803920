import {
  useIsFetching
} from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import "./authentication/auth.css";
import Enterotp from "./authentication/Enterotp";
import Forget from "./authentication/Forget";
import Login from "./authentication/Login";
import Resetpassword from "./authentication/Resetpassword";
import Loader from "./components/Loader/Loader";
import NotFound from "./components/NotFound";
import AdminRoute from "./routes/AdminRoute";
import { AdminAuth, PublicAuth } from "./utils/ProtectedRoute";
import ScrollTop from "./utils/ScrollTop";

function App() {
  const isFetching = useIsFetching();

  return (
    <>
      <ScrollTop />
      <Routes>
        <Route path="*" element={<NotFound />} />

        {/*********************Admin Routes*********************/}
        <Route path="*" element={<AdminAuth />}>
          <Route path="admin/*" element={<AdminRoute />} />
        </Route>

        {/*********************All Public Routes*********************/}
        <Route path="*" element={<PublicAuth />}>
          <Route path="" element={<Login />} />
          <Route path="forget" element={<Forget />} />
          <Route path="otp" element={<Enterotp />} />
          <Route path="reset-password/:id" element={<Resetpassword />} />
        </Route>
      </Routes>
      {!!isFetching && <Loader />}
    </>
  );
}

export default App;
