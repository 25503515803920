import React from "react";
import { Modal } from "react-bootstrap";

const ImageModal = ({ show, setShow }) => {
  return (
    <Modal
      show={!!show}
      onHide={() => setShow(false)}
      animation={true}
      fullscreen={true}
    >
      <Modal.Header closeButton />

      <Modal.Body>
        <img src={show}  />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
