import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { login } from "../redux/features/authSlice";
import { signIn } from "../services/services";
import { EyeIcon, HideIcon } from "../SvgIcons/allIcons";
import "./auth-responsive.css";
import "./auth.css";
import { constant } from "../utils/constants";
import { toastAlert } from "../utils/SweetAlert";
import Loader from "../components/Loader/Loader";

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      email: yup.string().required().email().label(t("login.email")).trim(),
      password: yup.string().required().label(t("login.password")).trim(),
    }),
    onSubmit: (values) => {
      let body = {
        email: values?.email?.trim(),
        password: values?.password,
      };

      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => signIn(body),
    onSuccess: (resp) => {
      if (resp?.data?.data?.role == constant.ROLES.SUPER_ADMIN) {
        dispatch(login(resp?.data?.data));
        resetForm();
      } else {
        toastAlert("error", t("errorMsg.invalidCredential"));
      }
    },
  });

  return (
    <section className="auth-main">
      <Container fluid className="p-0">
        <Row className="m-0 ">
          <Col lg={6} className="px-0">
            <div className="auth_img_wrp">
              <img src="/images/auth-img.png" alt="img" />
            </div>
          </Col>
          <Col lg={6}>
            <div className="auth-card">
              <div className="auth_logo">
                <Link to="/">
                  <img src="images/logo.svg" alt="" />{" "}
                </Link>
              </div>
              <div className="auth_heading">
                <h1>{t("login.login")}</h1>
                <p className="mb-0">{t("login.enterEmailandPassword")}</p>
              </div>
              <Form>
                <Row>
                  <Col lg={12}>
                    <Form.Group className="mb-4 loginInput">
                      <label>{t("login.email")}</label>
                      <div className="position-relative">
                        <img
                          className="inputicon"
                          src="/images/message.svg"
                        ></img>
                        <Form.Control
                          type="text"
                          placeholder={t("login.enterEmail")}
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                      </div>

                      <small className="text-danger">
                        {touched.email && errors.email}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col lg={12}>
                    <Form.Group className="mb-4 position-relative eye_wrp loginInput">
                      <label>{t("login.password")}</label>
                      <div className="position-relative">
                        <img className="inputicon" src="/images/lock.svg"></img>
                        <Form.Control
                          type={showPass ? "text" : "password"}
                          placeholder={t("login.enterPassword")}
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="current-password"
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSubmit();
                            }
                          }}
                        />
                        {showPass ? (
                          <span
                            className="eye-icon"
                            onClick={() => {
                              setShowPass(false);
                            }}
                          >
                            <EyeIcon />
                          </span>
                        ) : (
                          <span
                            className="eye-icon"
                            onClick={() => {
                              setShowPass(true);
                            }}
                          >
                            <HideIcon />
                          </span>
                        )}
                      </div>
                      <small className="text-danger">
                        {touched.password && errors.password}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col className="text-end">
                    <Link className="linktext forgottext" to="/forget">
                      {t("login.forgotPassword")}
                    </Link>
                  </Col>

                  <Col lg={12} className="text-end mt-3">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn-theme"
                    >
                      {t("login.submit")}
                    </button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      {mutation.isPending && <Loader />}
    </section>
  );
};

export default Login;
