import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CrossIcon } from "../../SvgIcons/allIcons";
import DropDown from "../../components/DropDown";
import {
  associateBrand,
  getAllBrand,
  getCompanyLocation,
  updateAssociatedBrand,
} from "../../services/services";
import { constant } from "../../utils/constants";
import { toastAlert } from "../../utils/SweetAlert";

const BrandModal = ({ show, handleClose, header, refetch }) => {
  const { t } = useTranslation();
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      id: "",
      brand: "",
      locations: [],
    },
    validationSchema: yup.object().shape({
      brand: yup.object().shape({
        value: yup.string().required().label(t("addCompany.brandName")),
      }),
      locations: yup.array().min(1).label(t("addCompany.location")),
    }),
    onSubmit: (values) => {
      let body = {
        brandId: values?.brand?.value,
        locations: values?.locations?.map((i) => i.value),
      };
      mutation.mutate(body);
    },
  });

  const mutation = useMutation({
    mutationFn: (body) =>
      values?.id
        ? updateAssociatedBrand(values?.id, body, header)
        : associateBrand(body, header),
    onSuccess: (resp) => {
      resetForm();
      handleClose();
      refetch();
      toastAlert("success", resp?.data?.message);
    },
  });

  const loadBrands = async (search, loadedOptions, { page }) => {
    let resp = await getAllBrand(page, search);
    let array = (await resp?.data?.data?.brands) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadLocations = async (search, loadedOptions, { page }) => {
    let resp = await getCompanyLocation(
      header,
      page,
      constant.PER_PAGE_TEN,
      search
    );
    let array = (await resp?.data?.data?.locations?.locations) ?? [];
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.locations?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    if (!!show?.brand?._id) {
      setValues({
        ...values,
        id: show?._id,
        brand: { label: show?.brand?.name, value: show?.brand?._id },
        locations: show?.locations?.map((i) => ({
          label: i.name,
          value: i._id,
        })),
      });
    }
  }, [show?.brand?._id]);

  return (
    <Modal
      show={!!show}
      onHide={() => {
        handleClose();
        resetForm();
      }}
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
        <Modal.Title>
          {values?.id ? t("brand.updateBrand") : t("brand.addBrand")}{" "}
        </Modal.Title>
        <Button
          variant="link"
          onClick={() => {
            handleClose();
            resetForm();
          }}
          className="close_Btn"
        >
          <CrossIcon />
        </Button>
      </Modal.Header>

      <Modal.Body className="newPlan_ModalBody">
        <Form>
          <div className="mb-3">
            <Row>
              <Col md={12}>
                <div>
                  <label htmlFor="brandName" className="companyLabel">
                    {t("addCompany.brandName")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <DropDown
                    placeholder={t("addCompany.enterBrandName")}
                    id="brandName"
                    loadOptions={loadBrands}
                    isClearable={false}
                    onChange={(e) => setFieldValue("brand", e)}
                    value={values?.brand}
                    onBlur={() => setFieldTouched("brand", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched.brand && errors.brand?.value}
                  </small>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <label htmlFor="location" className="companyLabel">
                    {t("addCompany.location")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <DropDown
                    placeholder={t("addCompany.enterLocationName")}
                    id="location"
                    loadOptions={loadLocations}
                    isMulti={true}
                    isClearable={false}
                    onChange={(e) => setFieldValue("locations", e)}
                    value={values?.locations}
                    onBlur={() => setFieldTouched("locations", true)}
                  />
                  <small className="text-danger ms-1">
                    {touched.locations && errors.locations}
                  </small>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="newPlan_ModalFooter">
        <Button
          variant="secondary"
          className="userBtn btn-theme planModalFooter_Btn"
          type="button"
          onClick={handleSubmit}
        >
          {t("brand.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BrandModal;
