import React from "react";
import { Col, Row } from "react-bootstrap";
import useSlider from "../../hooks/useSlider";
import Sidebar from "../sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDetails from "../../hooks/useDetails";

const MyProfile = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const details = useDetails();

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={12} >
            <h2 className="mainhead font-24 myProfile_Txt">
              {t("profile.myProfile")}
            </h2>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row>
            <Col lg={8}>
              <div className="ProfileOuter">
                <div className="profileImg">
                  <img
                    src={
                      details?.userInfo?.profilePhoto
                        ? details?.userInfo?.profilePhoto
                        : "/images/profile.jpg"
                    }
                    className="profile_Img img-fluid"
                  ></img>
                </div>
                <div className="ProfileInput">
                  <p className="colorLight font-16">{t("profile.name")}:</p>
                  <p className="mb-4 colorDark font-20">
                    {details?.userInfo?.name}
                  </p>

                  <p className="colorLight font-16">
                    {t("profile.emailAddress")}:
                  </p>
                  <p className="mb-4 colorDark font-20">{details?.email}</p>
                </div>
              </div>
            </Col>
            <div className="col-lg-4 text-end">
              <Link className="userBtn btn-theme" to="../editprofile">
                {t("profile.edit")}
              </Link>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
