import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Stepper } from "react-form-stepper";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  addCompany,
  getOneCompnay,
  updateCompany,
} from "../../services/services";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const AddNewCompany = () => {
  const { t } = useTranslation();
  const isSlider = useSlider();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam?.get("id");

  useQuery({
    queryKey: ["company", id],
    queryFn: async () => {
      const resp = await (id && getOneCompnay(id));
      setValues({
        ...values,
        companyName: resp?.data?.data?.name,
        corporateAddress: {
          address: resp?.data?.data?.corporateAddress?.fullAddress,
          streeAddress: resp?.data?.data?.corporateAddress?.street,
          city: resp?.data?.data?.corporateAddress?.city,
          state: resp?.data?.data?.corporateAddress?.state,
          country: resp?.data?.data?.corporateAddress?.country,
          zipCode: resp?.data?.data?.corporateAddress?.zipCode,
        },
      });
      return true;
    },
  });

  const mutation = useMutation({
    mutationFn: (body) => (id ? updateCompany(id, body) : addCompany(body)),
    onSuccess: (resp) => {
      navigate(
        `../companylocation/${resp?.data?.data?.uuid}?id=${resp?.data?.data?._id}`
      );
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: {
      companyName: "",
      corporateAddress: {
        address: "",
        streeAddress: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
    },
    validationSchema: yup.object().shape({
      companyName: yup
        .string()
        .required()
        .label(t("addCompany.companyName"))
        .trim()
        .max(100)
        .min(2),

      corporateAddress: yup.object().shape({
        address: yup
          .string()
          .required()
          .label(t("addCompany.address"))
          .trim()
          .min(2),
        streeAddress: yup
          .string()
          .required()
          .label(t("addCompany.streetAddress"))
          .trim()
          .min(2),
        city: yup.string().required().label(t("addCompany.city")).trim().min(2),
        state: yup
          .string()
          .required()
          .label(t("addCompany.state"))
          .trim()
          .min(2),
        country: yup
          .string()
          .required()
          .label(t("addCompany.country"))
          .trim()
          .min(2),
        zipCode: yup
          .string()
          .required()
          .label(t("addCompany.zipCode"))
          .matches(/^[a-zA-Z0-9]*$/, t("errorMsg.invalidZipCode"))
          .trim(),
      }),
    }),

    onSubmit: (values) => {
      let body = {
        corporateAddress: {
          fullAddress: values?.corporateAddress?.address,
          street: values?.corporateAddress?.streeAddress,
          city: values?.corporateAddress?.city,
          state: values?.corporateAddress?.state,
          country: values?.corporateAddress?.country,
          zipCode: values?.corporateAddress?.zipCode,
          lat: 30.6575339,
          long: 76.7269559,
        },
        name: values?.companyName,
      };

      mutation.mutate(body);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row>
          <Col xl={6}>
            <NavigateBack path="../companymanagement">
              {t("addCompany.addNewCompany")}
            </NavigateBack>
          </Col>
        </Row>

        <div className="basic_Info company-info">
          <Stepper
            steps={[
              { label: t("addCompany.basicInfo") },
              { label: t("addCompany.brandLocation") },
            ]}
            activeStep={0}
          />
          <Form>
            <Row>
              <Col md={12}>
                <div className="mt-2">
                  <label htmlFor="name" className="companyLabel">
                    {t("addCompany.companyName")}{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="companyName"
                    value={values?.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="commonInput"
                    placeholder={t("addCompany.enterCompanyName")}
                  />
                  <small className="text-danger ms-1">
                    {touched.companyName && errors.companyName}
                  </small>
                </div>
              </Col>

              <div>
                <h3 className="infoTxt fontSize16">
                  {t("addCompany.companyAddress")}
                </h3>
                <Row>
                  <Col md={6}>
                    <div className="mt-2">
                      <label htmlFor="locationAddress" className="companyLabel">
                        {t("addCompany.address")}{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="locationAddress"
                        className="commonInput"
                        placeholder={t("addCompany.enterAddress")}
                        name="corporateAddress.address"
                        value={values?.corporateAddress?.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched?.corporateAddress?.address &&
                          errors?.corporateAddress?.address}
                      </small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mt-2">
                      <label htmlFor="streeAddress" className="companyLabel">
                        {t("addCompany.streetAddress")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="streeAddress"
                        className="commonInput"
                        placeholder={t("addCompany.enterStreetAddress")}
                        name="corporateAddress.streeAddress"
                        value={values?.corporateAddress?.streeAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched.corporateAddress?.streeAddress &&
                          errors.corporateAddress?.streeAddress}
                      </small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mt-2">
                      <label htmlFor="city" className="companyLabel">
                        {t("addCompany.city")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="city"
                        className="commonInput"
                        placeholder={t("addCompany.enterCity")}
                        name="corporateAddress.city"
                        value={values?.corporateAddress?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched.corporateAddress?.city &&
                          errors.corporateAddress?.city}
                      </small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mt-3">
                      <label htmlFor="state" className="companyLabel">
                        {t("addCompany.state")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="state"
                        className="commonInput"
                        placeholder={t("addCompany.enterState")}
                        name="corporateAddress.state"
                        value={values?.corporateAddress?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched.corporateAddress?.state &&
                          errors.corporateAddress?.state}
                      </small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mt-3">
                      <label htmlFor="country" className="companyLabel">
                        {t("addCompany.country")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="country"
                        className="commonInput"
                        placeholder={t("addCompany.enterCountry")}
                        name="corporateAddress.country"
                        value={values?.corporateAddress?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <small className="text-danger ms-1">
                        {touched.corporateAddress?.country &&
                          errors.corporateAddress?.country}
                      </small>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mt-3">
                      <label htmlFor="zipCode" className="companyLabel">
                        {t("addCompany.zipCode")}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="zipCode"
                        className="commonInput"
                        placeholder={t("addCompany.enterZipCode")}
                        name="corporateAddress.zipCode"
                        value={values?.corporateAddress?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={10}
                      />
                      <small className="text-danger ms-1">
                        {touched.corporateAddress?.zipCode &&
                          errors.corporateAddress?.zipCode}
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Row>
          </Form>
          <div className="btnGroup">
            <button
              className="userBtn btn-theme ms-auto d-block fontSize18"
              type="button"
              onClick={handleSubmit}
            >
              {t("addCompany.next")}
            </button>
          </div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
};

export default AddNewCompany;
