import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import CompanyDropDown from "../../components/CompanyDropDown";
import useSlider from "../../hooks/useSlider";
import { getItems } from "../../services/services";
import { constant } from "../../utils/constants";
import Pagination from "../../utils/Pagination";
import Sidebar from "../sidebar/Sidebar";
import i18next from "i18next";

const ItemsList = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const { state } = useLocation();
  const [company, setCompany] = useState(!!state ? state : "");
  const [page, setPage] = useState(constant.PAGE_ONE);
  const [limit, setLimit] = useState(constant.PER_PAGE_TEN);

  const { data } = useQuery({
    queryKey: ["item-list", page, limit, company?.domain],
    queryFn: async () => {
      const resp =
        company?.domain && (await getItems(page, limit, company?.domain));
      return resp?.data?.data ?? [];
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="mainhead font-24">{t("item.items")}</h2>
          <div className="d-flex align-items-center gap-2">
            <CompanyDropDown setCompany={setCompany} company={company} />
            <button className="userBtn btn-theme me-2">
              {t("globals.export")}
            </button>
            {/* <Link className="userBtn btn-theme" to="../add-item">
              {t("globals.addNew")}
            </Link> */}
          </div>
        </div>
        <div className="table-responsive tableOut mt-4">
          <table className="table tableDashboard">
            <thead className="thead-dark">
              <tr>
                <th>{t("item.itemName")}</th>
                <th>{t("item.itemNameSpanish")}</th>
                <th>{t("item.category")}</th>
                <th>{t("item.applyToLocation")}</th>
                <th>{t("item.itemSellPrice")}</th>
                <th>{t("item.expiration")}</th>
                <th>{t("globals.action")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.items && data?.items?.length > 0 ? (
                data?.items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.en_name}</td>
                      <td>{item?.es_name}</td>
                      <td>
                        {" "}
                        {i18next?.language == "es"
                          ? item?.categoryDetails?.es_name
                          : item?.categoryDetails?.en_name}
                      </td>
                      <td>
                        {item?.assignAll
                          ? t("globals.allLocations")
                          : item?.locations?.map((i) => i.name)?.join(", ")}
                      </td>
                      <td>{item?.additionalInfo?.sellingPrice}</td>
                      <td>
                        <p className="greenBox">{`${item?.expirationTime?.days}d:${item?.expirationTime?.hours}h:${item?.expirationTime?.mins}m`}</p>
                      </td>
                      <td>
                        <Link
                          to={`../item-details/${item?._id}`}
                          state={company}
                        >
                          <img
                            src="/images/eye.svg"
                            className="img-fluid actionIcon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="text-center">
                    <h4>{t("item.notFound")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalPages={data?.total_pages}
          total={data?.total}
          length={data?.items?.length}
        />
      </div>
    </div>
  );
};

export default ItemsList;
