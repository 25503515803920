import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useSlider from "../../hooks/useSlider";
import {
  AddIcon,
  CheckMark,
  CloseIcon,
  CrossIcon,
} from "../../SvgIcons/allIcons";
import Sidebar from "../sidebar/Sidebar";
import "./paymentAndBilling.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toastAlert } from "../../utils/SweetAlert";
import {
  addPlan,
  deletePlan,
  getPlans,
  updatePlan,
} from "../../services/services";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import DeleteModal from "../../components/DeleteModal";

const PaymentAndBilling = () => {
  const isSlider = useSlider();
  const [showDelete, setShowDelete] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    resetForm();
  };
  const handleShow = () => setShow(true);
  const { t } = useTranslation();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    setValues,
    resetForm,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      id: "",
      planName: "",
      price: "",
      interval: "",
      intervalCount: "",
      addOn: [{ name: "" }],
    },
    validationSchema: yup.object().shape({
      planName: yup.string().required().label(t("payment.planName")).trim(),
      price: yup.number().required().min(0).label(t("payment.price")),
      interval: yup.string().required().label(t("payment.interval")),
      intervalCount: yup
        .number()
        .required()
        .min(1)
        .max(12)
        .label(t("payment.intervalCount")),
      addOn: yup.array().of(
        yup.object().shape({
          name: yup.string().required().label(t("payment.addOn")).trim(),
        })
      ),
    }),
    onSubmit: (values) => {
      let body = {
        name: values?.planName,
        interval: values?.interval,
        unitAmount: values?.price,
        intervalCount: values?.intervalCount,
        benefits: values?.addOn?.map((i) => i.name),
      };

      mutation.mutate(body);
    },
  });

  const handleAddOn = (event, index) => {
    let data = [...values?.addOn];
    data[index][event.target.name] = event.target.value;
    setFieldValue("addOn", data);
  };

  const mutation = useMutation({
    mutationFn: (body) =>
      values?.id ? updatePlan(values?.id, body) : addPlan(body),
    onSuccess: (resp) => {
      handleClose();
      refetch();
      toastAlert("success", resp?.data?.message);
    },
  });

  const { data, refetch } = useQuery({
    queryKey: ["plan-list"],
    queryFn: async () => {
      const resp = await getPlans();
      return resp?.data?.data;
    },
  });

  const handleEdit = (data) => {
    setValues({
      ...values,
      id: data?._id,
      planName: data?.name,
      price: data?.unitAmount,
      interval: data?.interval,
      intervalCount: data?.intervalCount,
      addOn: data?.benefits?.map((i) => ({ name: i })),
    });
    handleShow();
  };

  const deleteMutation = useMutation({
    mutationFn: (id) => deletePlan(id),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <section
        className={isSlider ? "body-content close" : "body-content open"}
      >
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="main-heading fontSize24">
              {t("payment.paymentBilling")}
            </h2>
            <button
              className="userBtn btn-theme fontSize18"
              onClick={handleShow}
            >
              {t("payment.createNew")}
            </button>
          </div>

          <Row>
            <Col md={12}>
              {data?.plans && data?.plans?.length > 0 ? (
                data?.plans?.map((item, index) => {
                  return (
                    <div
                      className="plan-parent d-flex justify-content-between align-items-center"
                      key={index}
                    >
                      <div>
                        <h3 className="plan_Txt fontSize20">{item?.name}</h3>

                        <h3 className="freeTxt fontSize24">
                          ${item?.unitAmount}
                          <span className="fontSize16">/{item?.interval}</span>
                        </h3>
                        <p className="fontSize16">
                          Last Updated: {moment(item?.updatedAt).format("ll")}
                        </p>
                      </div>
                      <div className="includes_Parent">
                        <h3 className="includerTxt fontSize14">INCLUDES:</h3>
                        {item?.benefits?.map((i, ind) => {
                          return (
                            <p className="includes_Data" key={ind}>
                              <CheckMark />{" "}
                              <span className="fontSize14">{i}</span>
                            </p>
                          );
                        })}
                      </div>
                      <div>
                        <button
                          className="userBtn btn-theme fontSize18 me-2"
                          onClick={() => handleEdit(item)}
                        >
                          {t("payment.edit")}
                        </button>
                        <button
                          className="delBtn btn-theme fontSize18"
                          onClick={() => setShowDelete(item?._id)}
                          type="button"
                        >
                          {t("globals.delete")}
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <center className="mt-4">
                  <h4 className="main-heading fontSize24">
                    {t("payment.noPlansFound")}
                  </h4>
                </center>
              )}
            </Col>
          </Row>
        </div>
      </section>

      {/* Start Create New Plan Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header className="newPlan_ModalHeader" closeButton={false}>
          <Modal.Title>
            {values?.id ? t("payment.editPlan") : t("payment.createNewPlan")}
          </Modal.Title>
          <Button variant="link" onClick={handleClose} className="close_Btn">
            <CrossIcon />
          </Button>
        </Modal.Header>

        <Modal.Body className="newPlan_ModalBody">
          <form action="">
            <div className="createPlanform_Child">
              <label htmlFor="planName" className="companyLabel">
                {t("payment.planName")} <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="planName"
                className="commonInput"
                placeholder={t("payment.enterPlanName")}
                name="planName"
                value={values?.planName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <small className="text-danger ms-1">
                {touched.planName && errors.planName}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="price" className="companyLabel">
                {t("payment.price")} <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="price"
                className="commonInput"
                placeholder={t("payment.enterPrice")}
                name="price"
                value={values?.price}
                min={1}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <small className="text-danger ms-1">
                {touched.price && errors.price}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="interval" className="companyLabel">
                {t("payment.interval")} <span className="text-danger">*</span>
              </label>
              <select
                className="commonInput"
                name="interval"
                id="interval"
                value={values?.interval}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select</option>
                <option value="week">{t("payment.week")}</option>
                <option value="month">{t("payment.month")}</option>
                <option value="year">{t("payment.year")}</option>
              </select>
              <small className="text-danger ms-1">
                {touched.interval && errors.interval}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="intervalCount" className="companyLabel">
                {t("payment.intervalCount")}{" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="intervalCount"
                className="commonInput"
                placeholder={t("payment.enterIntervalCount")}
                name="intervalCount"
                value={values?.intervalCount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <small className="text-danger ms-1">
                {touched.intervalCount && errors.intervalCount}
              </small>
            </div>
            <div className="createPlanform_Child">
              <label htmlFor="name" className="companyLabel">
                {t("payment.addOn")} <span className="text-danger">*</span>
              </label>
              {values?.addOn?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="d-flex align-items-center my-2">
                      <input
                        type="text"
                        id="name"
                        className="commonInput me-2"
                        placeholder={t("payment.enterAddOn")}
                        name="name"
                        value={item?.name}
                        onChange={(e) => handleAddOn(e, index)}
                        onBlur={() =>
                          setFieldTouched(`addOn.${index}.name`, true)
                        }
                      />
                      {values?.addOn?.length - 1 == index ? (
                        <div
                          onClick={() => {
                            setFieldValue("addOn", [
                              ...values?.addOn,
                              { name: "" },
                            ]);
                          }}
                        >
                          <AddIcon className="addPlan_Icon" />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            let data = [...values?.addOn];
                            data?.splice(index, 1);
                            setFieldValue("addOn", data);
                          }}
                        >
                          <CloseIcon className="addPlan_Icon" />
                        </div>
                      )}
                    </div>
                    <small className="text-danger ms-1">
                      {touched?.addOn?.at(index)?.name &&
                        errors?.addOn?.at(index)?.name}
                    </small>
                  </React.Fragment>
                );
              })}
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer className="newPlan_ModalFooter">
          <Button
            variant="secondary"
            className="userBtn btn-theme planModalFooter_Btn"
            onClick={handleSubmit}
            type="button"
          >
            {values?.id ? t("payment.update") : t("payment.create")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/******************************Delete Modal*************************/}
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />

      {mutation.isPending && <Loader />}
    </div>
  );
};

export default PaymentAndBilling;
