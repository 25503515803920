import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  deleteCompanyLocation,
  getLocationDetails,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const CompanyLocationDetails = () => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const { id, header } = useParams();
  const { t } = useTranslation();
  const isSlider = useSlider();

  const { data, refetch } = useQuery({
    queryKey: ["location-details", id, header],
    queryFn: async () => {
      const resp = await getLocationDetails(id, header);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCompanyLocation(id, header),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      navigate(-1);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <NavigateBack> {t("addCompany.locationDetails")}</NavigateBack>
          <div>
            {/* <button className="userBtn btn-theme fontSize18 me-3">
              {t("globals.edit")}
            </button> */}
            <button
              className="delBtn btn-theme fontSize18"
              onClick={() => setShowDelete(data?._id)}
              type="button"
            >
              {t("globals.delete")}
            </button>
          </div>
        </div>
        <div className="basic_Info company-info">
          <div>
            <p className="font-18 colorDark mb-2">
              {t("addCompany.locationName")}:{" "}
              <span className="colorLight ms-2">{data?.name}</span>
            </p>
          </div>
          <Row className="mt-4">
            <Col md={3}>
              <div>
                <h3 className="colorDark  font-18 mb-3">
                  {t("addCompany.billingAddress")}
                </h3>
                <p className="fontSize16">
                  {data?.billingAddress?.fullAddress} <br />{" "}
                  {data?.billingAddress?.street}
                  <br />
                  {data?.billingAddress?.city}
                  {data?.billingAddress?.state}
                  <br /> {data?.billingAddress?.zipCode}{" "}
                  {data?.billingAddress?.country}
                </p>
              </div>
            </Col>
            <Col md={1} className="CenterLine"></Col>
            <Col md={3}>
              <div>
                <h3 className="colorDark  font-18 mb-3">
                  {" "}
                  {t("addCompany.deviceAddress")}
                </h3>
                <p className="fontSize16">
                  {data?.deviceAddress?.fullAddress} <br />{" "}
                  {data?.deviceAddress?.street}
                  <br />
                  {data?.deviceAddress?.city} {data?.deviceAddress?.state}{" "}
                  <br />
                  {data?.deviceAddress?.zipCode}
                  {data?.deviceAddress?.country}
                </p>
              </div>
            </Col>
            <Col md={1} className="CenterLine"></Col>
            <Col md={3}>
              <div>
                <h3 className="colorDark  font-18 mb-3">
                  {" "}
                  {t("addCompany.deviceInfo")}
                </h3>
                <p className="font-18 colorLight mb-2 ">
                  {t("addCompany.deviceId")}:{" "}
                  <span className=" colorDark ms-2">
                    {data?.deviceInfo?.deviceId}
                  </span>
                </p>
                <p className="font-18 colorLight mb-2">
                  {t("addCompany.printerSerialNumber")}:
                  <span className=" colorDark ms-2">
                    {" "}
                    {data?.deviceInfo?.serialNumber}
                  </span>
                </p>
                <p className="font-18 colorLight mb-2">
                  {t("addCompany.printerModel")}:{" "}
                  <span className=" colorDark ms-2">
                    {" "}
                    {data?.deviceInfo?.model}
                  </span>
                </p>
                <p className="font-18 colorLight mb-2">
                  {t("addCompany.trackingNumber")}:{" "}
                  <span className=" colorDark ms-2">
                    {" "}
                    {data?.deviceInfo?.trackingNumber}
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default CompanyLocationDetails;
