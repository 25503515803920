import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import {
  deleteCompanyLocation,
  getBrandDetails,
} from "../../services/services";
import { toastAlert } from "../../utils/SweetAlert";
import AdminFooter from "../AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import "./companyManagement.css";

const BrandDetails = () => {
  const [showDelete, setShowDelete] = useState(false);
  const { id, header } = useParams();
  const { t } = useTranslation();
  const isSlider = useSlider();

  const { data, refetch } = useQuery({
    queryKey: ["brand-details", id, header],
    queryFn: async () => {
      const resp = await getBrandDetails(id, header);
      return resp?.data?.data;
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id) => deleteCompanyLocation(id, header),
    onSuccess: (resp) => {
      refetch();
      setShowDelete(false);
      toastAlert("success", resp?.data?.message);
    },
  });

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <NavigateBack>{t("brand.brandDetails")}</NavigateBack>
        </div>
        <div className="basic_Info company-info">
          <div>
            <p className="font-18 colorDark mb-3">
              {t("brand.brandName")}:{" "}
              <span className="colorLight ms-2">
                {data?.associatedBrand?.brandId?.name}
              </span>
            </p>
            <p className="font-18 colorDark">{t("brand.locations")} </p>
          </div>
          <div className="table-responsive companyManagTable tableOut mt-4">
            <table className="table tableDashboard">
              <thead className="thead-dark">
                <tr>
                  <th>{t("brand.name")}</th>
                  <th>{t("brand.location")}</th>
                  <th>{t("globals.createdAt")}</th>
                  <th>{t("globals.action")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.locations && data?.locations?.length > 0 ? (
                  data?.locations?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>{item?.billingAddress?.fullAddress}</td>
                        <td>{moment(item?.createdAt).format("lll")}</td>
                        <td>
                          <Link to={`../location/${item?._id}/${header}`}>
                            <img
                              src="/images/eye.svg"
                              className="img-fluid actionIcon"
                            ></img>
                          </Link>
                          <Link
                            to={`../companylocation/${header}?id=${data?._id}`}
                          >
                            <img
                              src="/images/Edit.svg"
                              className="img-fluid actionIcon"
                            />
                          </Link>

                          <img
                            src="/images/Delete.svg "
                            className="img-fluid actionIcon"
                            onClick={() => setShowDelete(item?._id)}
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <h4>{t("brand.locationNotFound")}</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <AdminFooter />
      <DeleteModal
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        handleDelete={deleteMutation.mutate}
      />
    </div>
  );
};

export default BrandDetails;
