import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {} from "../../SvgIcons/allIcons";
import NavigateBack from "../../components/NavigateBack";
import useSlider from "../../hooks/useSlider";
import { getUserDetails } from "../../services/services";
import Sidebar from "../sidebar/Sidebar";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

const UserDetails = () => {
  const isSlider = useSlider();
  const { t } = useTranslation();
  const { id } = useParams();

  const { data } = useQuery({
    queryKey: ["user-details", id],
    queryFn: async () => {
      const resp = await getUserDetails(id, true);
      return resp?.data?.data;
    },
  });

  const displayPermissions = (permissions) => {
    return permissions
      ? Object.entries(permissions)
          .filter(([key, value]) => value === true) // Filter true permissions
          .map(
            ([key]) =>
              key
                .replace(/_/g, " ") // Replace underscores with spaces
                .replace(/can /, "") // Remove the 'can' prefix
                .replace(/\b\w/g, (letter) => letter.toUpperCase()) // Capitalize each word
          )
      : [];
  };

  const permissions = "";

  return (
    <div className="mainbox">
      <Sidebar />
      <div className={isSlider ? "body-content close" : "body-content open"}>
        <Row className="mb-2">
          <Col xl={6}>
            <NavigateBack> {t("user.userDetails")}</NavigateBack>
          </Col>
          <Col className="text-end" xl={6}>
            <span className="useridText font-14 colorDark">
              {t("user.custId")}:
            </span>
            <span className="useridText useridFeild font-14 colorDark">
              {data?.userId}
            </span>
          </Col>
        </Row>
        <div className="tableOuter">
          <Row className="mb-5">
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.companyName")}</p>
              <p>{data?.userCompany?.name}</p>
            </Col>
            <Col xl={1} className="CenterLine"></Col>
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.brandName")}</p>
              <p>{data?.userBrand?.name}</p>
            </Col>
            <Col xl={1} className="CenterLine"></Col>
            <Col className="infoColumn" xl={3}>
              <p className="colorDark font-18">{t("user.role")}</p>
              <p className="text-capitalize"> {data?.role}</p>
            </Col>
            <Col className="infoColumn mt-5" xl={6}>
              <p className="colorDark font-18">{t("user.basicInfo")}</p>
              <p>
                {t("user.name")}:{" "}
                <span className="colorDark text-capitalize">
                  {data?.userInfo?.name}
                </span>
              </p>
              <p>
                {t("user.emailAddress")}:{" "}
                <span className="colorDark">{data?.email}</span>
              </p>
              <p>
                {t("user.phoneNumber")}:{" "}
                <span className="colorDark text-nowrap">
                  {data?.userInfo?.dialCode} {data?.userInfo?.mobile}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mb-3">
            <p className="colorDark font-18 mb-2">Permissions</p>
            {displayPermissions(data?.userPermissions)?.map((item, index) => {
              if (index % 2 == 0) {
                return (
                  <React.Fragment key={index}>
                    <Col className="infoColumn" xl={5}>
                      <ul>
                        <li className="mb-3">{item}</li>
                      </ul>
                    </Col>

                    <Col className="CenterLine" xl={2}></Col>
                  </React.Fragment>
                );
              } else {
                return (
                  <Col className="infoColumn" xl={5} key={index}>
                    <ul>
                      <li className="mb-3">{item}</li>
                    </ul>
                  </Col>
                );
              }
            })}
          </Row>

          <label className="font-18 colorDark pb-3">
            {t("brand.locations")}
          </label>
          <div className="table-responsive tableOut">
            <table className="table tableDashboard">
              <thead className="thead-dark">
                <tr>
                  <th>{t("user.name")}</th>
                  <th>{t("user.location")}</th>
                  <th>{t("user.added")}</th>
                </tr>
              </thead>
              <tbody>
                {data?.userDetails?.userLocations &&
                data?.userDetails?.userLocations?.length > 0 ? (
                  data?.userDetails?.userLocations?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.name}</td>
                        <td>
                          {item?.billingAddress?.fullAddress}{" "}
                          {item?.billingAddress?.street}{" "}
                          {item?.billingAddress?.city}{" "}
                          {item?.billingAddress?.state}{" "}
                          {item?.billingAddress?.zipCode}{" "}
                          {item?.billingAddress?.country}
                        </td>
                        <td>{moment(item?.createdAt).format("lll")}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      <h4>{t("brand.locationNotFound")}</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
