import React from "react";
import { useTranslation } from "react-i18next";
import { getAllCompany } from "../services/services";
import { constant } from "../utils/constants";
import DropDown from "./DropDown";

const CompanyDropDown = ({ company, setCompany }) => {
  const { t } = useTranslation();

  const loadCompany = async (search, loadedOptions, { page }) => {
    let resp = await getAllCompany(page, constant.PER_PAGE_TEN, search);
    let array = (await resp?.data?.data?.companies) ?? [];
    if (!company) {
      setCompany({
        value: array?.at(0)?._id,
        label: array?.at(0)?.name,
        domain: array?.at(0)?.uuid,
      });
    }
    return {
      options: array.map((item) => ({
        value: item?._id,
        label: item?.name,
        domain: item?.uuid,
      })),
      hasMore: loadedOptions.length == resp?.data?.data?.total ? false : true,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <div className="d-flex align-items-center">
      <h5 className="me-3">Filter by company :</h5>
      <DropDown
        placeholder={t("user.selectCompany")}
        id="company"
        classNamePrefix="customSelect"
        loadOptions={loadCompany}
        isClearable={false}
        onChange={(e) => {
          setCompany(e);
        }}
        defaultOptions={true}
        value={company}
      />
    </div>
  );
};

export default CompanyDropDown;
