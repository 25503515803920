export const numOnly = (e) => {
  if (
    !/[0-9]/.test(e.key) && // Prevent non-numeric input
    e.key !== "Backspace" &&
    e.key !== "Delete" &&
    e.key !== "ArrowLeft" &&
    e.key !== "ArrowRight" &&
    e.key !== "Tab"
  ) {
    e.preventDefault();
  }
};
